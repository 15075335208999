import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import { connect } from 'react-redux'
import AlertConfirmContext from '../contexts/modalRef'
import { clearPooledTimer, startPooledTimer } from '../reactcomponents/Moment'
import HeaderCtnr, {
  AlertConfirmCtnr,
  ToastAlertCtnr,
  HotkeysHelperCntr,
  NotificationListCtnr,
  PostIframeLinkCtnr,
  AnnouncementModalCtnr
} from '../common/v5/headerCtnr.js'
import {
  AdminEditCtnr,
  AgentEditCtnr,
  PopupInsertProtectCtnr
} from '../views/v5/adminCtnr'
import Footer from '../common/v5/footerCntr.js'
import Routes from '../routes/v5/workflow.js'
import { withRouter } from 'react-router'
import {
  ClassificationCtnr,
  PopupNotificationCtnr,
  ContactBookModalCtnr,
  PostponeErrandCtnr,
  KnowledgeBasePopupCntr,
  AcquireErrandsModalCtnr,
  RewriteAnswerPopupCntr
} from '../views/v5/errandCtnrs'
import {
  ScheduleReportCtnr,
  ShareReportCtnr,
  ConfigureTimeFormCtnr
} from '../views/v5/statisticsCtnr'
import { ManualErrandModalCtnr } from '../views/v5/workflowCtnr'
import { CallPadPopupCtnr, SipDialerCtnr } from './call'
import OldChatCtnr from './oldchat' // TODO: remove this
import DrilldownTableCtnr from './drilldown'
import PrintCtnr from './print'
import OtherContactErrandCtnr from './oce'
import { appReady } from '../redux/actions/domain'
import {
  onceFetchWorkflowSettings,
  onceFetchWorkflowLimitedSettings,
  loadWfLimitedSettings
} from '../redux/actions/async/workflow'
import {
  reviewFeatureEnabledMemo,
  reviewFolderEnabledMemo
} from '../redux/selectors/review'
import {
  NewIMModalCtnr,
  AgentBookModalCtnr,
  GroupBookModalCtnr
} from '../views/v5/internalMsgListCtnr'
import { getExtQueueType } from '../common/v5/helpers'
import { DARK_MODE_APPEARANCE } from '../common/v5/constants'
import { getLocalWfLimtedSettings } from '../common/v5/indexeddb'
//dark-mode components
import {ThemeProvider} from "styled-components";
// import { GlobalStyles } from "../components/v5/globalStyles"; //TODO: remove the old DarkMode files once new one is confirmed
import { GlobalStyles } from "../components/v5/GlobalStyling"; //New Dark Mode styles
import  {useDarkMode as DarkMode} from "../components/v5/useDarkMode"
import { lightTheme, darkTheme } from "../components/v5/Theme";
import DarkModeToggle from "react-dark-mode-toggle";
import { SkeletonLoader } from '../components/v5/SkeletonFrames';
import { EmailTestPopupCtnr } from '../views/v5/accounts/email';


const Styledc3Wrapper = styled.main`
	padding: ${props => props.noPadding ? "0px" : "0px 10px"};
`

// NOTE: this component was refactor-ed into NewApp. Any update here please
// update the NewApp too.
class OldApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasWorkflowSettings: false,
      hotkeys: false,
      centionKnowledgeBase: false,
      theme: false,
      darkToggle: false
    };
    this.themeToggler = this.themeToggler.bind(this);
  }
  componentDidMount() {
    startPooledTimer();
    this.props.onLoad();
    let loadLimited = false;
    getLocalWfLimtedSettings(activeUserId).then((wfSettings) => {
      if(wfSettings != null) {
        this.props.setLimitedWfSettings(wfSettings)
          .then((settings) => {
            localStorage.setItem('theme', wfSettings.user_dark_mode)
            this.setState({
              hasWorkflowSettings: true,
              hotkeys: wfSettings.hotkeys,
              centionKnowledgeBase: features["cention-library"],
              theme: wfSettings.user_dark_mode
            });
            this.props.onAppReady();
          });
      } else {
        let qType = getExtQueueType();
        if(qType != "" && qType != "undefined"){
          loadLimited = true;
        }
        this.props.fetchWorkflowSettings(loadLimited)
          .then((settings) => {
            localStorage.setItem('theme', settings.user_dark_mode)
            this.setState({
              hasWorkflowSettings: true,
              hotkeys: settings.hotkeys,
              // TODO: use props to pass features global variable instead
              // directly access here
              centionKnowledgeBase: features["cention-library"],
              theme: settings.user_dark_mode
            });
            this.props.onAppReady();
          });
      }
    });
  }

  componentWillUnmount() {
    clearPooledTimer();
  }

  themeToggler() {
    let theme = !(this.state.theme);
    let mode = theme == true ? 'dark' : 'light';
    localStorage.setItem('theme', mode)
    this.setState({
      theme: theme
    });
  }

  render() {
    const { reviewEnabled, reviewFolderEnabled } = this.props
      , ready = this.state.hasWorkflowSettings
      ;

    const {theme, darkToggle} = this.state;
    const whiteLogo = process.env.CLOUDFRONT_URL + "/img/logo-yellow.png";
    const allReady = ready && this.props.workflowReady;
    const themeMode = theme === true ? darkTheme : lightTheme ;
    let printClass;
    let darkModeToggle = darkToggle;
    let themeModes;
    if (this.props.printEnabled) {
      printClass = 'cention-no-print';
    }

    darkModeToggle = false;
    themeModes = themeMode;

    if ( false ) {
      return (
        <div className="bg-splash-main">
          <div className="header-logo" style={{padding:"21px 17px 14px"}} hidden={features['hide-cention-logo']}>
            <img src={whiteLogo} alt="" />
            <span className="sign" style={{color: "#ffaf11"}}>Contact Center</span>
          </div>
          <div className="overlay">
            <svg className="progress-bar-countdown">
              <circle className ="circle-background" r="38" cx="45" cy="45"></circle>
              <circle className="circle-loader" r="38" cx="45" cy="45"></circle>
            </svg>
          </div>
        </div>
      )
    } else {
          return (
            <ThemeProvider theme={themeModes}>
              <GlobalStyles/>
              <div className={printClass}>
                <Styledc3Wrapper noPadding={true}>
                  {!allReady && <div hidden={ready} className="bg-splash-main">
                    <div className="header-logo" style={{padding:"21px 17px 14px"}} hidden={features['hide-cention-logo']}>
                      <img src={whiteLogo} alt="" />
                      <span className="sign" style={{color: "#ffaf11"}}>Contact Center</span>
                    </div>
                    <div className="overlay">
                      {/* <svg className="progress-bar-countdown">
                        <circle className ="circle-background" r="38" cx="45" cy="45"></circle>
                        <circle className="circle-loader" r="38" cx="45" cy="45"></circle>
                      </svg> */}
                      <div className="spinner"></div>
                    </div>
                  </div>
                  }
                  <div className={classNames("main-header-section", {"embedded": true})}>
                    <HeaderCtnr ready={ready} />
                    {darkModeToggle &&
                      <DarkModeToggle
                            size={30}
                            onChange={this.themeToggler}
                            checked={theme}
                            className="dark-mode"
                            speed={2.0}
                            />
                    }
                  </div>
                  <div className={classNames("main-body-section", {"embedded": true})}>
                    <Routes
                      ready={ready}
                      reviewEnabled={reviewEnabled}
                      reviewFolderEnabled={reviewFolderEnabled}
                    />
                  </div>
                <Footer class="main-footer-section" />
                </Styledc3Wrapper>
                <OldChatCtnr /> {/* TODO: remove this */}
                <DrilldownTableCtnr />
                <PrintCtnr />
                <ManualErrandModalCtnr />
                <PostIframeLinkCtnr />
                <ContactBookModalCtnr />
                <ScheduleReportCtnr />
                <ConfigureTimeFormCtnr />
                <ShareReportCtnr />
                <PostponeErrandCtnr />
                <AgentEditCtnr />
                <AdminEditCtnr />
                <PopupInsertProtectCtnr />
                <AcquireErrandsModalCtnr />
                <OtherContactErrandCtnr />
                {this.state.centionKnowledgeBase &&
                  <KnowledgeBasePopupCntr />
                }
                <ClassificationCtnr />
                <div className="pop-up-notification-wrapper">
                  <PopupNotificationCtnr />
                </div>
                {this.state.hotkeys &&
                  <HotkeysHelperCntr />
                }
                <NotificationListCtnr />
                <AnnouncementModalCtnr />
                <CallPadPopupCtnr />
                <NewIMModalCtnr />
                <AgentBookModalCtnr />
                <GroupBookModalCtnr />
                <AlertConfirmCtnr /> {/* always place alert box as last */}
              </div>
            </ThemeProvider>
          )
      }
  }
}

const whiteLogo = process.env.CLOUDFRONT_URL + '/img/logo-yellow.png'

const headerLogoStyle = { padding: '21px 17px 14px' }

const headerLogoSignStyle = { color: '#ffaf11' }

const CustomSpinner = ({type}) => {
  if(type === "dotted") {
    return (
      <div className="sk-chase spin-center">
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
      </div>
    )
  }
  return <div className='spinner' />
}

const Ready = ({ ready, src }) => (
  <div hidden={ready} className='bg-splash-main'>
    <div className='header-logo' style={headerLogoStyle} hidden={features['hide-cention-logo']}>
      <img src={src} alt='' />
      <span className='sign' style={headerLogoSignStyle}>Contact Center</span>
    </div>
    <div className='overlay'>
      <CustomSpinner type="dotted"/>
    </div>
  </div>
)

const NewApp = ({
  fetchWorkflowSettings,
  onAppReady,
  onLoad,
  printEnabled,
  reviewEnabled,
  reviewFolderEnabled,
  setLimitedWfSettings,
  workflowReady,
  darkToggle,
  ui
}) => {

  const [hasWorkflowSettings, setHasWorkflowSettings] = useState(false)
  const [hotkeys, setHotkeys] = useState(false)
  const [centionKnowledgeBase, setCentionKnowledgeBase] = useState(false)
  const ready = hasWorkflowSettings
  const printClass = classNames({ 'cention-no-print': printEnabled })
  const allReady = ready && workflowReady
  const [themes, themeToggler, mountedComponent] = DarkMode();
  const [theme, setTheme] = useState(false)
  const themeMode = theme === true ? darkTheme : lightTheme ;
  const [modalRef, setModalRef] = useState(null)
  const handleRefChange = useCallback(modalRef => {
    setModalRef(modalRef)
  }, [])
  const refCtx = useMemo(() => ({ modalRef }), [modalRef])
  useEffect(() => {
    startPooledTimer()
    onLoad()
    getLocalWfLimtedSettings(activeUserId).then(wfSettings => {
      if (wfSettings != null) {
        setLimitedWfSettings(wfSettings)
        return wfSettings
      }
      const qType = getExtQueueType()
      const loadLimited = qType != '' && qType != 'undefined'
      return fetchWorkflowSettings(loadLimited)
    }).then(settings => {
      setHasWorkflowSettings(true)
      setHotkeys(settings.hotkeys)
      darkToggle = false;
      const darkModeAppearance = settings.user_dark_mode_appearance;
      let darkTheme = false;
      switch (darkModeAppearance) {
        case (DARK_MODE_APPEARANCE[0].id):
          darkTheme = false;
          break;
        case (DARK_MODE_APPEARANCE[1].id):
          darkTheme = true;
          break;
        case (DARK_MODE_APPEARANCE[2].id):
          if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            darkTheme = true;
          } else {
            darkTheme = false;
          }
          break;
        default:
          console.log("Unexpected user_dark_mode_appearance value:", darkModeAppearance);
          break;
      }
      localStorage.setItem('theme', darkTheme);
      setTheme(darkTheme);
      setCentionKnowledgeBase(features['cention-library'])
      onAppReady()
    })
    return () => {
      clearPooledTimer()
    }
    // NOTE: refactor-ed code, emulate previous code.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if(!mountedComponent) return <div/>
  return (
    <ThemeProvider theme={themeMode}>
      <GlobalStyles isDarkMode = {theme}/>
        <AlertConfirmContext.Provider value={refCtx}>
            <div className={printClass}>
              <Styledc3Wrapper className='wrapper' noPadding={true}>
                {/* {!allReady && <Ready ready={ready} src={whiteLogo} />} */}
                <div className={classNames("main-header-section", {"embedded": true})}>
                  <HeaderCtnr ready={ready} />
                  {darkToggle &&
                    <DarkModeToggle
                      size={30}
                      onChange={themeToggler}
                      checked={theme}
                      className="dark-mode"
                      speed={2.0}
                      />
                  }
                </div>
                {!allReady && <SkeletonLoader
                  hide={ready}
                  view={location.pathname}
                  collapseSideBar={ui.collapseSideBar}
                  showSideBar={ui.showSideBar}
                  showConversationList={false}
                />}
                <div className={classNames("main-body-section", {"embedded": true})}>
                  <Routes
                    ready={ready}
                    reviewEnabled={reviewEnabled}
                    reviewFolderEnabled={reviewFolderEnabled}
                    themeToggler={themeToggler}
                  />
                </div>
                <Footer class='main-footer-section' />
              </Styledc3Wrapper>
              <OldChatCtnr /> {/* TODO: remove this */}
              <DrilldownTableCtnr />
              <PrintCtnr />
              <ManualErrandModalCtnr />
              <PostIframeLinkCtnr />
              <ContactBookModalCtnr />
              <ScheduleReportCtnr />
              <ShareReportCtnr />
              <PostponeErrandCtnr />
              <AgentEditCtnr />
              <AdminEditCtnr />
              <PopupInsertProtectCtnr />
              <AcquireErrandsModalCtnr />
              <OtherContactErrandCtnr />
              { centionKnowledgeBase && <KnowledgeBasePopupCntr /> }
              <RewriteAnswerPopupCntr />
              <ClassificationCtnr />
              <div className='pop-up-notification-wrapper'>
                <PopupNotificationCtnr />
              </div>
              {hotkeys && <HotkeysHelperCntr />}
              <NotificationListCtnr />
              <AnnouncementModalCtnr />
              <CallPadPopupCtnr />
              <NewIMModalCtnr />
              <AgentBookModalCtnr />
              <GroupBookModalCtnr />
              <SipDialerCtnr />
              <EmailTestPopupCtnr/>
              <ToastAlertCtnr />
              <AlertConfirmCtnr onCustomComponentRefChange={handleRefChange} /> {/* always place alert box as last */}
            </div>
        </AlertConfirmContext.Provider>
    </ThemeProvider>
  )
}

const mapState = state => ({
  printEnabled: state.app.print.show,
  reviewEnabled: reviewFeatureEnabledMemo(state),
  reviewFolderEnabled: reviewFolderEnabledMemo(state),
  workflowReady: state.app.workflow.globalReady,
  ui: state.app.workflow.ui
})

const mapDispatch = dispatch => ({
  onAppReady: () => {
    // app ready is different from on load and fetch workflow settings
    // as it is the state where fetch workflow settings finished the
    // first time when app container/component loaded. This is important
    // as it provides opportunity for certain state to have default
    // value based on data from the fetched workflow settings. Example:
    // manual errand lock-to-me flag.
    return dispatch(appReady())
  },
  onLoad: () => {
  },
  setLimitedWfSettings: (wfSettings) => {
    return dispatch(loadWfLimitedSettings(wfSettings))
  },
  fetchWorkflowSettings: (loadLimited) => {
    if (loadLimited) {
      return dispatch(onceFetchWorkflowLimitedSettings())
    }
    return dispatch(onceFetchWorkflowSettings())
  }
})

const AppCtnr = withRouter(connect(mapState, mapDispatch)(NewApp))

export default AppCtnr
