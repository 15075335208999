import React from "react";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import DrilldownTable from '../reactcomponents/DrilldownTable';
import { withAgentTimezonOffset } from '../views/v5/errandCtnrs';
import { hideDrilldownDataTableAndClearParameter } from '../redux/actions/statistics';
import { agentListSelector, getFolderListSelector } from '../redux/selectors/workflow';
import {
	allowAggregationDrilldown
	, closeActionMessagesMemoize
	, reportAreasSelector
	, reportDrilldownAggregationsSelector
	, reportDrilldownNormalizedDataSelector
	, reportDrilldownNotReadySelector
	, reportDrilldownResultsSelector
} from '../redux/selectors/statistics';
import { drilldownExport } from '../redux/actions/async/statistics';

const mapState = (state, props) => ({
	agents: agentListSelector(state, props).all
	, aggregations: reportDrilldownAggregationsSelector(state)
	, areas: reportAreasSelector(state).active
	, enableAggregation: allowAggregationDrilldown(state)
	, folders: getFolderListSelector(state)
	, closeActionMessages: closeActionMessagesMemoize(state)
	, normData: reportDrilldownNormalizedDataSelector(state, props)
	, results: reportDrilldownResultsSelector(state, props)
	, show: state.app.statistics.view.drilldown.show
	, notReady: reportDrilldownNotReadySelector(state, props)
});

const mapDispatch = dispatch => ({
	onClose: () => dispatch(hideDrilldownDataTableAndClearParameter())
	, onExport: () => dispatch(drilldownExport())
});

const DrilldownTableCtnr = compose(
	connect(mapState, mapDispatch)
	, withAgentTimezonOffset
)(DrilldownTable);

export default DrilldownTableCtnr;
