import React from 'react'
import { connect } from 'react-redux'
import { RPLY_ERRAND } from '../common/v5/constants'
// import { I } from '../../common/v5/config'
// import { getCurrentChatErrand } from '../common/v5/helpers'
import MessageCollaboration, { DataCRMSystems } from '../components/v5/MessageCollaboration'
import {
  getCollaborationLightSelector,
  getCollaborationListSelector,
  getEESummarySelector,
  hasCollaborationMemo
} from '../redux/selectors/collaborate'
import { notMineSelector } from '../redux/selectors/errand'
import { setupCollaborationQuery } from '../redux/actions/collaborate'
import {
  expandHideOption,
  inputTextChanges,
  selectShowReply,
  insertCollaborateAnsAttachment,
  expandCollabQueryThread
} from '../redux/actions/errand'
import {
  printCollaborationConversation,
  printCollaborationMessage
} from '../redux/actions/print'
import {
  fetchEEThread,
  replyExpertQuery,
  editExpertQuery,
  toggleCollaborationLight,
  saveCollabIfNeeded
} from '../redux/actions/async/collaborate'
import { detectLanguage, translation } from '../redux/actions/async/errand'

const mapCollaboration = (state, props) => {
  if (!hasCollaborationMemo(state)) {
    return { noEE: true }
  }
  const wf = state.app.workflow
  const { eid } = props
  const e = state.app.errand
  const c = e.currentErrand.collaboration
  return {
    notMine: notMineSelector(state, props) ? true : false,
    showCollaborate: e.ui.collaboration.show,
    ready: c.ee,
    listReady: c.ee && c.eeList,
    eeList: getCollaborationListSelector(state, props),
    light: getCollaborationLightSelector(state, props),
    eeSummary: getEESummarySelector(state, props),
    canTranslate: wf.fetchWfSettings.data.translation,
    interfaceLang: wf.fetchWfSettings.data.interface,
    filterPopup: e.ui.filterPopup,
    errandInputsAnswer: e.inputs.update_answer,
    showExactDayAndTime: wf.fetchWfSettings.data['show-exact-day-and-time'],
    expandCollabQueryThread: e.collaborationInputs.expandCollabThread,
  }
}

const mapCollaborationCallbacks = dispatch => ({
  onAnswerAttachmentInsert: attachment => {
    dispatch(insertCollaborateAnsAttachment(attachment))
  },
  onAnswerQuery: (threadID, queryID) => {
    dispatch(saveCollabIfNeeded())
    .then(() => {
      dispatch(setupCollaborationQuery(threadID, queryID))
    })
  },
  onPrintAction: (html, queryID, answerID, threadID, eid) => {
    let answer, id
    if (answerID > 0) {
      answer = true
      id = answerID
    } else {
      answer = false
      id = queryID
    }
    dispatch(printCollaborationMessage(threadID, answer, id))
  },
  onReplyAction: (html, queryID, answerID, threadID, eid) => {
    dispatch(saveCollabIfNeeded())
    .then(() => {
      dispatch(replyExpertQuery(eid, threadID, queryID, answerID))
    })
  },
  onExpandAction: (threadID, expand) => {
    dispatch(expandCollabQueryThread(threadID, expand))
  },
  onEditAction: (queryID, answerID, threadID, eid) => {
    dispatch(saveCollabIfNeeded())
    .then(() => {
      dispatch(editExpertQuery(eid, threadID, queryID, answerID))
    })
  },
  onPrintThread: thread => {
    dispatch(printCollaborationConversation(thread))
  },
  onToggleShow: (id, toggleState) => {
    dispatch(expandHideOption('collaboration', id, toggleState))
  },
  onLoadThread: (externalExpertThreadId, errandId, read) => {
    dispatch(fetchEEThread(externalExpertThreadId, errandId, read))
  },
  onToggleLight: (errandId, toggleState) => {
    dispatch(toggleCollaborationLight(errandId))
  },
  onTranslation: (field, html, from) => {
    if (field === 'detect') {
      return dispatch(detectLanguage(html))
    }
    if (field === 'translate') {
      return dispatch(translation(html, from))
    }
  },
  onCopyCollaborationMessage: (value, plain, isChat) => {
    if (isChat) {
      // TODO: copy message to chat is different
      return
    }
    dispatch(inputTextChanges('update_answer', value, plain))
    dispatch(selectShowReply(RPLY_ERRAND, true))
  }
})

const withCollaborateMsgCtnr = connect(
  mapCollaboration,
  mapCollaborationCallbacks
)

export const CollaborateMsgCtnr = withCollaborateMsgCtnr(MessageCollaboration)

export const CollabContentsCtnr = CollaborateMsgCtnr

const mapCRM = (state, props) => {
  const wf = state.app.workflow
  const { eid } = props
  const e = state.app.errand
  return {
    notMine: notMineSelector(state, props) ? true : false,
  }
}

const mapCRMCallbacks = dispatch => ({
  onLoadCRMData: errandId => {},
  onToggleShow: (id, toggleState) => {
    dispatch(expandHideOption('crmEmailTrigger', id, toggleState))
  },
});

const withCRMDataCtnr = connect(
  mapCRM,
  mapCRMCallbacks
);

export const CRMContentsDataCtnr = withCRMDataCtnr(DataCRMSystems);

export const CRMContentsCtnr = CRMContentsDataCtnr;
