import React from "react";
import { connect } from 'react-redux';
import { branch, compose, renderComponent } from 'recompose';
import { UNSELECT
	, ME_CREATE
	, ME_CREATE_AS_MY
	, ME_ST_IDLE
} from '../common/v5/constants';
import {
	BT_ACCEPT
	, BT_CANCEL
	, BT_CANCEL_OPEN_ERRAND
	, BT_CANCEL_XFER
	, BT_HANG_UP
	, BT_ERRAND
	, BT_FORWARD
	, BT_RECONNECT_TO_TWILIO
	, BT_REFRESH
	, BT_REJECT
	, BT_REJECTED_OK
	, BT_REPLACE
	, BT_RESET
	, BT_RETRY
	, BT_TRANSFER
	, TM_AGENT_ID
	, TM_PHONE_NUMBER
	// , txtChooseAgentForCallXfer
	, txtChooseCallXferTarget
	// , txtNoOtherAgent
	, txtNoTransferTarget
} from '../common/v5/callConstants';
import websocket from '../common/v5/agentsocket';
// import { withHeightCalculation } from '../reactcomponents/hocs';
import CallPad from '../reactcomponents/CallPad';
import DialPad from '../reactcomponents/DialPad';
import SimpleDialPad from '../reactcomponents/SimpleDialPad';
import SipDialer from "../components/v5/SipDialer";
import { createWithPopupMinDim } from "../reactcomponents/PopupPage";
import { doNothing } from '../reactcomponents/common'
import {
	callButtonsMemoize
	, callProfileMemoize
	, externalForwardableMemo
	, footerIconMemoize
	, footerTextMemoize
	, headerIconMemoize
	, headerTextMemoize
	, isCallPadPopupShown
	, isErrorOrNotReadyMemoize
	, onlyOtherAgents
	, selectedForwardeeIndex
	, selectedTransfereeAgentId
	, showDialPadMemoize
	, transferMethod
	, transferableOptionsMemo
	, sipCallShowDtmfKeypad
	, sipCallConn
	, sipCallTransferStatus
	, sipCallStatus
	, sipCallShowExtTransferKeypad
	, sipMakeCallCurrentErrand
} from '../redux/selectors/call';
import{
	manualCallMinimize
} from '../redux/selectors/manual';
import { currentCipherKeyMemoize, currentErrandDisplayIdMemo } from '../redux/selectors/errand';
import { isMobileSelector } from '../redux/selectors/mobile'
import { getOutboundPhoneTo, isCallMemoize } from '../redux/selectors/manual';
import { stopAutoOpenErrandTicker } from '../redux/reducers/call';
import {
	changeTransferee
	, closeCallPadPopup
	, updateTransferMethod
} from '../redux/actions/call';
import {
	accept
	, cancelOutbound
	, cancelWarmTransfer
	, externalForward
	, openErrandFromInboundCall
	, reconnect
	, reject
	, replaceWorkplace
	, resetWarmTransfer
	, retry
	, warmTransfer
} from '../redux/actions/async/call';
import {
	manualCallState
} from '../redux/actions/workflow';
import {
	onDtmfPressToAgent
	, showDtmfKeyboard
	, showExtTransferKeypad
	, sipColdTransfer
	, sipWarmTransfer
	, sipCloseManualErrandAfterColdTransfer
	, sipGetRefId
} from '../redux/actions/async/sippRtc';
import {
	submitManualErrand,
	forceSaveErrand,
	fetchContactBook,
	doCloseErrand
} from '../redux/actions/async/errand';
import {
	showContactBook,
} from '../redux/actions/errand';
import { composeWithDisplayName } from "../reactcomponents/hocs";

const mapCallPadState = (state, props) => ({
	buttonsCondition: callButtonsMemoize(state)
	, forwardeeOptions: externalForwardableMemo(state)
	, headerIcon: headerIconMemoize(state)
	, headerText: headerTextMemoize(state)
	, hideNoButtonText: isErrorOrNotReadyMemoize(state)
	, hideSelection: isErrorOrNotReadyMemoize(state)
	, footerIcon: footerIconMemoize(state)
	, footerText: footerTextMemoize(state)
	, profile: callProfileMemoize(state)
	, selected: selectedTransfereeAgentId(state)
	, selectedForwardee: selectedForwardeeIndex(state)
	, selectionOptions: onlyOtherAgents(state)
	, textNoItem: txtNoTransferTarget
	, textNoItemSelected: txtChooseCallXferTarget
	, transferMethod: transferMethod(state)
	, websocket
});

const buttonHandlerMap = {
	[BT_ACCEPT]: accept
	, [BT_CANCEL]: cancelOutbound
	, [BT_CANCEL_OPEN_ERRAND]: () => stopAutoOpenErrandTicker()
	, [BT_CANCEL_XFER]: cancelWarmTransfer
	, [BT_ERRAND]: openErrandFromInboundCall
	, [BT_FORWARD]: externalForward
	, [BT_HANG_UP]: reject
	, [BT_RECONNECT_TO_TWILIO]: reconnect
	, [BT_REFRESH]: doNothing
	, [BT_REJECT]: reject
	, [BT_REJECTED_OK]: resetWarmTransfer
	, [BT_REPLACE]: replaceWorkplace
	, [BT_RESET]: doNothing
	, [BT_RETRY]: retry
	, [BT_TRANSFER]: warmTransfer
};

const mapCallPadAction = (dispatch, props) => ({
	onClick: (buttonType, websocket) => {
		if (process.env.NODE_ENV !== 'production') {
			console.log("dbg: call button click:", buttonType, websocket);
		}
		const handler = buttonHandlerMap[buttonType];
		if (typeof handler === "undefined") {
			console.log("unhandled call pad button:", buttonType);
			return;
		}
		dispatch(handler(websocket));
	}
	, onEvent: (websocket, event, buttonType) => {
		if (process.env.NODE_ENV !== 'production') {
			console.log("dbg: call pad on event:", {event, buttonType});
		}
	}
	, onSelect: (id, data, arg) => {
		if (process.env.NODE_ENV !== 'production') {
			console.log("dbg: call agent selection:", {id, data, arg});
		}
		dispatch(changeTransferee(TM_AGENT_ID, id));
	}
	, onSelectForwardee: (transferee, data, arg) => {
		if (process.env.NODE_ENV !== 'production') {
			console.log(
				"dbg: call transfer selection:"
				, {transferee, data, arg}
			);
		}
		dispatch(changeTransferee(TM_PHONE_NUMBER, transferee));
	}
});

const CallPadCtnr = connect(mapCallPadState, mapCallPadAction)(CallPad);

const withDesktop = createWithPopupMinDim(400, 300);

const withMobile = createWithPopupMinDim(300, 300);

const CallPadPopup = composeWithDisplayName(
	"CallPadPopup"
	, branch(
		({ mobile }) => mobile
		, withMobile
		, withDesktop
	)
)(CallPadCtnr);

const mapCallPadPopupState = (state, props) => ({
	show: isCallPadPopupShown(state)
	, mobile: isMobileSelector(state)
});

const mapCallPadPopupAction = (dispatch, props) => ({
	onClose: () => {
		dispatch(closeCallPadPopup());
	}
});

export const CallPadPopupCtnr = connect(
	mapCallPadPopupState
	, mapCallPadPopupAction
)(CallPadPopup);

const mapDialPadState = (state, props) => ({
	phone: getOutboundPhoneTo(state, props)
	, show: showDialPadMemoize(state)
});

const mapDialPadAction = (dispatch, props) => ({
	// onCallClick: phoneValue => {
	// 	console.log("dbg: phone number:", phoneValue);
	// }
});

export const DialPadCtnr = compose(
	connect(mapDialPadState, mapDialPadAction)
	, branch(({ show }) => !show, renderComponent(CallPadCtnr))
)(DialPad);

const mapSimpleDialPadState = (state, props) => ({
	phone: getOutboundPhoneTo(state, props)
	, show: showDialPadMemoize(state)
});

const mapSimpleDialPadAction = (dispatch, props) => ({
	onClose: () => {
		dispatch(showDtmfKeyboard(false));
	}
});

export const SimpleDialPadCtnr = connect(mapSimpleDialPadState, mapSimpleDialPadAction)(SimpleDialPad);

const mapSipDialerState = (state) => {
	const e = state.app.errand,
		m = ((isCallMemoize(state) || manualCallMinimize(state))? state.app.errand.ui.manualCall : state.app.errand.ui.manual),
		wfs = state.app.workflow.fetchWfSettings,
		s = {
			sipCallConn: sipCallConn(state),
			sipCallTransferStatus: sipCallTransferStatus(state),
			sipShowDtmfKeypad: sipCallShowDtmfKeypad(state),
			sipCallStatus: sipCallStatus(state),
			sipShowExtTransferKeypad: sipCallShowExtTransferKeypad(state),
			mCipherKey: m.cipherKey,
			currentCipherKey: currentCipherKeyMemoize(state),
			sipServerName: (wfs.data ? wfs.data["sipServerName"] : ""),
			sipHideColdTransfer: (wfs.data ? wfs.data["sipHideColdTransfer"] : false),
			sipHideWarmTransfer: (wfs.data ? wfs.data["sipHideWarmTransfer"] : false),
			extRefId: m.extRefId,
			manualEid: m.newErrandId,
			errandId: e.currentErrand.id,
			displayId: currentErrandDisplayIdMemo(state),
			sipMakeCallCurrentErrand: sipMakeCallCurrentErrand(state),
		};
	return s;
};

const mapSipDialerAction = (dispatch, props) => ({
	sendDtmfToTransferAgent:(phone, key) => {
		dispatch(onDtmfPressToAgent(phone,key));
	},
	onClose: () => {
		dispatch(showExtTransferKeypad(false));
	},
	handleColdTransferToExternal: (eid, targetId, sipId, conn,
		displayId, mCipherKey, isManual, extRefId) => {
		if(eid != 0){
			if(isManual == true){
				dispatch(submitManualErrand(ME_CREATE, ME_CREATE_AS_MY,
					true, true, true, true))
				.then(res => {
					dispatch(sipCloseManualErrandAfterColdTransfer(eid,
						mCipherKey, extRefId));
					dispatch(sipColdTransfer(conn, sipId, targetId, displayId, true, true));
				});
			} else {
				let inExtRefId = sipGetRefId(conn);
				dispatch(forceSaveErrand())
				.then(res => {
					dispatch(doCloseErrand(eid, false, false, inExtRefId))
					.then(data => {
						dispatch(sipColdTransfer(conn, sipId, targetId, displayId, true, false));
					});
				});
			}
		} else {
			//when no errand created
			dispatch(sipCloseManualErrandAfterColdTransfer(eid, mCipherKey))
			dispatch(sipColdTransfer(conn, sipId, targetId,
				displayId, true));
		}
	},
	handleWarmTransferToExternal: (eid, targetId, sipId, conn,
		displayId, mCipherKey, isManual) => {
		dispatch(sipWarmTransfer(conn, sipId, targetId,
			displayId, eid, mCipherKey, isManual, true));
	},
	onCloseContactBook: () =>{
		dispatch(showContactBook(false));
		dispatch(fetchContactBook(""));
	},
});

export const SipDialerCtnr = connect(mapSipDialerState, mapSipDialerAction)(SipDialer);
