import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import each from 'lodash/each'
import { emptyObject } from '../common/constants'
import { isImageFilename } from '../common/v5/helpers'
import {
  TXT_UNABLE_TO_UPLOAD_FILE,
  UPLOAD_TYPE_IMAGE,
  UPLOAD_TYPE_TEMPLATE
} from '../common/v5/receiptGreetingConstants'
import { useFormUploadFile } from '../hooks/callback'
import {
  adminEditMemo,
  adminListMemo,
  fileArchiveImagesMemo,
  servicesAndAccountsMemo
} from '../redux/selectors/receiptGreeting'
import {
  orgAreaMemo,
  orgCompleteListsAreaMemo
} from '../redux/selectors/server'
import { ckeditorSettings } from '../redux/selectors/workflow'
import { updateAdminFinishEdit } from '../redux/actions/receiptGreeting'
import * as named from '../redux/actions/async/receiptGreeting'
import { composeWithDisplayName } from '../reactcomponents/hocs'
import { createDispatchers } from './hocs'

const { resolveUploadedFeedbackFile, ...handlers } = named

const mapState = (state, props) => {
  const feat = state.server.features, adminSt = state.app.admin
  return {
    data: adminListMemo(state),
    editData: adminEditMemo(state),
    orgArea: feat.isAdmin ? orgCompleteListsAreaMemo(state) : orgAreaMemo(state),
    servicesAndAccounts: servicesAndAccountsMemo(state),
    adminStatus: adminSt.admin.adminStatus
  }
}

const renameObject = (map, object) => {
  const newObject = {}
  each(object, (v, k) => { newObject[map[k]] = v })
  return newObject
}

// title: string
// handlers: object
// {
//   activate: function,
//   deleteItem: function,
//   setAdminEditField: function,
//   adminEdit: function,
//   updateAdminFinishEdit: function,
//   onceOnload: function,
//   save: function
// }
export const createDispatchMap = (title, handlers) => createDispatchers(
  title,
  renameObject(
    {
      activate: 'onActivate',
      deleteItem: 'onDelete',
      setAdminEditField: 'onEdit',
      adminEdit: 'onEditStart',
      updateAdminFinishEdit: 'onFinishEdit',
      onceOnload: 'onLoad',
      onChangeView: 'onChangeView',
      save: 'onSave'
    },
    handlers
  )
)

const mapDispatch = createDispatchMap(
  'receipt-greeting',
  { ...handlers, updateAdminFinishEdit }
)

const withReceiptGreetings = connect(mapState, mapDispatch)

export default withReceiptGreetings

const mapCkeditorDispatch = () => emptyObject

const mapCkeditorState = state => ({
  ckeditorSettings: ckeditorSettings(state),
  fileArchiveImages: fileArchiveImagesMemo(state)
})

const withMoreCkeditorProps = Component => ({
  ckeditorSettings: { fontFamily, fontSize, languageSrc },
  ...props
}) => (
  <Component
    fontFamily={fontFamily}
    fontSize={fontSize}
    languages={languageSrc}
    {...props}
  />
)

export const withCkeditor = composeWithDisplayName(
  'withCkeditor',
  connect(mapCkeditorState, mapCkeditorDispatch),
  withMoreCkeditorProps
)

const isZipped = mimeType => mimeType === 'application/zip'

export const useFeedbackUploadFileChange = (
  onChange,
  onPopAlert
) => {
  const postHandler = useCallback(
    (formData, file) => {
      let uploadType
      const { name, type } = file
      if (isImageFilename(name)) {
        uploadType = UPLOAD_TYPE_IMAGE
      } else if (isZipped(type)) {
        uploadType = UPLOAD_TYPE_TEMPLATE
      } else {
        onPopAlert(TXT_UNABLE_TO_UPLOAD_FILE)
        return
      }
      // NOTE: one caveat here, onChange and onPopAlert must be constant from
      // resolveUploadedFeedbackFile to promise resolved / rejected.
      resolveUploadedFeedbackFile(formData, uploadType)
        .then(file => {
          file.uploadType = uploadType
          onChange(file)
        })
        .catch(err => { onPopAlert(err) })
    },
    [onChange, onPopAlert]
  )
  return useFormUploadFile(postHandler, onPopAlert)
}
