// Provide some components that allow handle action which can clear the errand
// view component to be cancelled by user when errand view component have data
// not saved.
import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	FolderBorder
	, SimpleLink
} from '../reactcomponents/ContextFolder';
import {
	withCancellableLink
	, withCancellableClick
} from './hocs';

export const CancellableSimpleLink = withCancellableClick(SimpleLink);

export class ContextFolder extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(e) {
		if (typeof this.props.onClick === "function") {
			this.props.onClick(this.props.id, this.props.value);
		}
	}
	render() {
		return (
			<FolderBorder data-qa-id={this.props["data-qa-id"]}
				hidden={this.props.hidden}
				active={this.props.active}
			>
				<CancellableSimpleLink onClick={this.handleClick}
					text={this.props.icon ? <i className={this.props.icon}></i>  : this.props.text}
					withCount={this.props.withCount}
					count={this.props.count}
				/>
			</FolderBorder>
		);
	}
}

const CancellableLink = withCancellableLink(Link);

export const ContextLink = ({linkTo, hidden, text, ...props}) => (
	<FolderBorder data-qa-id={props["data-qa-id"]} hidden={hidden}>
		<CancellableLink to={{pathname: linkTo}}>
			{text}
		</CancellableLink>
	</FolderBorder>
);

export default CancellableLink;
