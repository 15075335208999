import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { I } from '../common/v5/config'
import {
  currentCollabChannel,
  currentErrandReplyChannel,
  getCurrentReply,
  getChannelOpts,
  getCollabOpts,
  getCurrentOpenErrandID,
  socialActionPerChannel
} from '../redux/selectors/errand'
import {
  selectShowReplyAction,
  toggleReplyBoxErrandMenu,
  showHideReplyChannel
} from '../redux/actions/errand'
import { checkBeforeSetChannel, socialMediaReplyAction } from '../redux/actions/async/errand'
import ReplyToolbar from '../components/v5/ReplyToolbar'

const mapReplyToolbar = state => {
  const e = state.app.errand
  const ui = e.ui
  const reply = e.ui.reply
  const wf = state.app.workflow
  const wfs = wf.fetchWfSettings
  const ckConf = wfs.data.answerWysiwyg
  return {
    replyAs: getCurrentReply(state),
    selectedChannel: currentErrandReplyChannel(state),
    selectedCollabChannel: currentCollabChannel(state),
    errandId: getCurrentOpenErrandID(state),
    collabSlackEnabled: wfs.data.collabSlackEnabled,
    collabMSTeamEnabled: wfs.data.collabMSTeamEnabled,
    collabJiraEnabled: wfs.data.collabJiraEnabled,
    collabGoogleChatEnabled: wfs.data.collabGoogleChatEnabled,
    allowChangeChannel: wfs.data['change-channel'],
    showActionSelection: reply.showAction,
    shouldDisplaySocialAction: socialActionPerChannel(state),
    showChannelSelection: reply.showChannel,
    preferredSpellcheckLang: wfs.data['spell'],
    collaborationChannels: wfs.data.collaborationChannels,
    channelOpts: getChannelOpts(state),
    collabOpts: getCollabOpts(state),
    showReplyboxErrandsMenu: ui.showReplyboxErrandsMenu,
    spellcheckLangs: ckConf.languageSrc ? ckConf.languageSrc : ''
  }
}

const mapReplyToolbarDispatch = dispatch => ({
  onToggleChannel: toggleState => {
    dispatch(showHideReplyChannel(toggleState))
  },
  onToggleAction: toggleState => {
    dispatch(selectShowReplyAction(toggleState))
  },
  onToggleReplyBoxErrandMenu: (toggle) => {
    dispatch(toggleReplyBoxErrandMenu(toggle));
  },
  onSelectChannel: channel => {
    dispatch(checkBeforeSetChannel(channel))
  },
  onSelectAction: (eid, action, channel) => {
    dispatch(socialMediaReplyAction(eid, action, channel));
  }
})

export const withReplyToolbar = connect(mapReplyToolbar,mapReplyToolbarDispatch)

export const ReplyToolbarCtnr = withReplyToolbar(ReplyToolbar)
