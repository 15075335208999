import React from "react";
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { emptyObject } from '../common/constants';
import { withHeightCalculation } from '../reactcomponents/hocs';
import { Chart } from '../reactcomponents/Chart';
import {
	chartOnload
	, chartOnUnload
	, requestImageResult
} from '../redux/actions/async/statistics';
import {
	updateShareParam
} from '../redux/actions/statistics';
import { featureSelector } from '../redux/selectors/server';
import {
	makeChartDataSelector,
	makeChartExtraSelector,
	makeChartImageRequestedSelector,
	makeChartParamSelector,
	makeChartSourceSelector,
	makeChartTypeSelector,
	statisticAreasFromReportId,
	hideTitleAndLinkSelector
} from '../redux/selectors/statistics';

const mapChartState = () => {
	const dataSelector = makeChartDataSelector()
		, extraSelector = makeChartExtraSelector()
		, paramSelector = makeChartParamSelector()
		, typeSelector = makeChartTypeSelector()
		, imageRequestedSelector = makeChartImageRequestedSelector()
		, mapState = (state, props) => ({
			data: dataSelector(state, props)
			, extra: extraSelector(state, props)
			, param: paramSelector(state, props)
			, type: typeSelector(state, props)
			, imageRequested: imageRequestedSelector(state, props)
			, hideTitleAndLink: hideTitleAndLinkSelector(state, props)
		})
		;
	return mapState;
};

const mapChartDispatch = (dispatch, props) => {
	return {
		onLoad: (id, layout, position, idx, index, param) => {
			if (props.noAutoFetchOnLoad) {
				return;
			}
			dispatch(chartOnload(id, layout, position, idx, index, param));
		},
		onUnload: (id, layout, position, idx, index) => {
			if (props.noUnLoad) {
				return;
			}
			dispatch(chartOnUnload(id, layout, position, idx, index));
		},
		onChartImage: (image) => {
			dispatch(updateShareParam("imageLeftChart", image));
		},
		onRequestImageDone: (identifier, success, url) => {
			dispatch(requestImageResult(identifier, success, url));
		}
	};
};

const mapChartSourceState = () => {
	const sourceSelector = makeChartSourceSelector()
		, mapState = (state, props) => {
			const result = sourceSelector(state, props);
			return result;
		}
		;
	return mapState;
};

export const ChartCtnr = compose(
	connect(mapChartSourceState)
	, connect(mapChartState, mapChartDispatch)
	, withHeightCalculation
)(Chart);

export const withChartParameters = connect(
	(state, props) => ({
		features: featureSelector(state)
		, reportAreas: statisticAreasFromReportId(state, props)
	})
	, () => emptyObject
)
