import React from "react";
import { compose } from "recompose";
import { connect } from 'react-redux';
import ActionModalPrint, { Print } from '../reactcomponents/Modal';
import { withPrintNode } from './hocs';
import {
	disablePrint
} from '../redux/actions/print';
import {
	fetchPrintErrands
} from '../redux/actions/async/print';
import {
	errandPrint
} from '../redux/actions/async/workflow';
import {
	getPrintBodySelector,
	getPrintTypeSelector,
	getPrintInfo
} from '../redux/selectors/print';

const mapActionModalPrintState = (state, props) => {
	const e = state.app.errand;
	return {
		id: "printErrand",
		show: e.ui.shouldPopPrint,
		printInfo: getPrintInfo(state,props),
		body: (e.printContent.data ? e.printContent.data : ""),
		isHeader: true,
		headerText: I("Print errand"),
		isFooter: true,
		isChat: e.errand.isChat
	}
}

export const ActionModalPrintCtnr = connect(mapActionModalPrintState)(ActionModalPrint);

const mapState = (state, props) => {
	return {
		show: state.app.print.show,
		printInfo: getPrintInfo(state,props),
		body: getPrintBodySelector(state, props),
		type: getPrintTypeSelector(state, props),
		delay: 1000,
		isHeader: !!state.app.print.header,
		headerText: state.app.print.header,
		isFooter: true
	};
};

const mapDispatch = dispatch => {
	return {
		onClose: currentShow => dispatch(disablePrint())
	};
};

const PrintCtnr = compose(
	connect(mapState, mapDispatch)
	, withPrintNode
)(Print);

export default PrintCtnr;
