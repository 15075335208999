import React from "react";
import { connect } from 'react-redux';
import { I, L } from '../common/v5/config';
import {
	AGENT_TYPES,
	AOT_DATE_INTERVAL,
	AOT_ERRAND_TYPE,
	AOT_SHOW_INACTIVE,
	CS_EXIST,
	CS_REPORT,
	DATE_TYPES,
	DATE_COMPARE,
	DDG_ADVANCE,
	DDG_AGENT_TYPES,
	DDG_DATE_TYPE,
	DDG_DATE_COMPARE, 
	DDG_ERRAND_TYPE,
	DDG_EXISTING_OV_CHARTS,
	DDG_GROUPS,
	DDG_INTERVAL_DATE,
	DDG_MULTI_AREAS,
	DDG_MULTI_AGENTS,
	DDG_MULTI_CHANNELS,
	DDG_MULTI_SERVICE_IDS,
	DDG_MULTI_SIMPLE_TAGS,
	DDG_MULTI_TAGS,
	DDG_REL_DATETIME,
	DDG_SORT_ATTR,
	DDG_SORT_DIRECTION,
	DDG_TIME_FORMAT,
	DDG_TIME_SPAN_FORMAT,
	DDG_TIME_ZONE,
	RELATIVE_DATE_TYPES,
	DDG_WIDGET_REPORT_TYPE,
	DDG_WIDGET_REPORT_LIST,
	DDG_WIDGET_CHART_TYPE,
	DDG_AGGREGATE_TYPE,
	AGGREGATE_TYPE,
	DDG_MULTI_CONNECTEDAREAS,
	DDG_COLLABORATORS,
} from '../common/v5/constants';
import {
	agentListSelector,
	areaListSelector,
	serviceIDsSelector,
	serverChannelsSelector,
	simpleTagsSelector,
	tagsSelector,
	connectedAreaListSelector,
} from '../redux/selectors/workflow';
import {
	groupListSelector,
	timeFormatListSelector,
	timeSpanFormatListSelector,
	timeZoneListSelector,
	getAllReports,
	CollaboratorsSelector
} from '../redux/selectors/statistics';

const dataSelections = {
	[DDG_MULTI_AREAS]: {
		name: 'areas'
		, selector: areaListSelector
	}
	, [DDG_MULTI_AGENTS]: {
		name: 'agents'
		, selector: agentListSelector
	}
	, [DDG_MULTI_CHANNELS]: {
		name: 'channels'
		, selector: serverChannelsSelector
	}
	, [DDG_MULTI_SERVICE_IDS]: {
		name: 'channels'
		, selector: serviceIDsSelector
	}
	, [DDG_MULTI_SIMPLE_TAGS]: {
		name: 'tags'
		, selector: simpleTagsSelector
	}
	, [DDG_MULTI_TAGS]: {
		name: 'tags'
		, selector: tagsSelector
	}
	, [DDG_ADVANCE]: {
		name: 'advanceOptions'
		, data: [
			{id: AOT_SHOW_INACTIVE, value: I("Show inactive")}
		]
	}
	, [DDG_ERRAND_TYPE]: {
		name: 'errandTypes'
		, data: [
			{id: 0, value: I("All")}
			, {id: 1, value: I("Answered")}
			, {id: 2, value: I("Unanswered")}
			, {id: 3, value: I("Deleted")}
		]
	}
	, [DDG_COLLABORATORS]: {
		name: 'collaborators'
		, selector: CollaboratorsSelector
	}
	, [DDG_DATE_TYPE]: {
		name: 'dateTypes'
		, data: DATE_TYPES
	}
	, [DDG_DATE_COMPARE]: {
		name: 'dateCompare'
		, data: DATE_COMPARE
	}
	, [DDG_REL_DATETIME]: {
		name: 'relativeDateTypes'
		, data: RELATIVE_DATE_TYPES
	}
	, [DDG_AGENT_TYPES]: {
		name: 'agentTypes'
		, data: AGENT_TYPES
	}
	, [DDG_GROUPS]: {
		name: 'groups'
		, selector: groupListSelector
	}
	, [DDG_TIME_FORMAT]: {
		name: 'timeFormats'
		, selector: timeFormatListSelector
	}
	, [DDG_TIME_SPAN_FORMAT]: {
		name: 'timeSpans'
		, selector: timeSpanFormatListSelector
	}
	, [DDG_TIME_ZONE]: {
		name: 'timeZones'
		, selector: timeZoneListSelector
	}
	, [DDG_SORT_DIRECTION]: {
		name: 'sortDirection'
		, data: [
			{id: 0, value: I("ASC")}
			, {id: 1, value: I("DESC")}
		]
	}
	, [DDG_SORT_ATTR]: {
		name: 'sortAttr'
		, data: [
			{id: 0, value: I("Date")}
			, {id: 1, value: I("ID")}
			, {id: 2, value: I("Subject")}
			, {id: 3, value: I("Area")}
			, {id: 4, value: I("Agent")}
			, {id: 5, value: I("From")}
			, {id: 6, value: I("Closed")}
		]
	}
	, [DDG_WIDGET_REPORT_TYPE]: {
		name: 'widgetReportType'
		, data : [
			{id: CS_REPORT, name: I("Report")}
			, {id: CS_EXIST, name: I("Existing")}
		]
	}
	, [DDG_WIDGET_REPORT_LIST]: {
		name: 'widgetReportList'
		, selector: getAllReports
	}
	, [DDG_WIDGET_CHART_TYPE]: {
		name: 'widgetChartType'
	}
	, [DDG_EXISTING_OV_CHARTS]: {
		name: 'existingChart'
	}
	, [DDG_AGGREGATE_TYPE]: {
		name: 'aggregateType'
		, data : AGGREGATE_TYPE
	}
	, [DDG_MULTI_CONNECTEDAREAS]: {
		name: 'areas'
		, selector: connectedAreaListSelector
	}

};

const emptyObject = {};

const connectWithSelector = (name, selector) => (state, props) => {
	if (typeof props[name] !== 'undefined') {
		return emptyObject;
	}
	return {[name]: selector(state, props)};
};

const connectWithData = (name, data) => Component => props => {
	let dataProp;
	if (typeof props[name] !== 'undefined') {
		dataProp = emptyObject;
	} else {
		dataProp = {[name]: data};
	}
	return <Component {...props} {...dataProp} />;
};

const defDataName = 'data';

export const connectData = type => {
	const s = dataSelections[type];
	if (s.selector) {
		return connect(connectWithSelector(defDataName, s.selector));
	}
	return connectWithData(defDataName, s.data);
};
