// TODO: remove this temporary chat UI.
import React from "react";
import { connect } from 'react-redux';
import {
	toggleWorkflowPopup
} from '../redux/actions/workflow';
import Chat from '../views/v5/oldChat';
import {
	UI_SHOW_OLD_CHAT,
	WFP_OLD_CHAT
} from '../common/v5/constants';

const mapChatState = (state, props) => {
	const s = {
			show: state.app.workflow.ui[UI_SHOW_OLD_CHAT]
		};
	return s;
};

const mapChatDispatch = (dispatch, props) => {
	return {
		onToggleShow: value => {
			dispatch(toggleWorkflowPopup(WFP_OLD_CHAT, value));
		}
	};
};

const OldChatCtnr = connect(mapChatState, mapChatDispatch)(Chat);

export default OldChatCtnr;
