import { connect } from 'react-redux'
import {
  adminEditMemo as adminAWSSESEditMemo,
  identitiesMemo,
  identityMemo
} from '../redux/selectors/awsses'
import {
  awssesMemo,
  byRegionIdAWSSESMemo
} from '../redux/selectors/c3constants'
import { adminEditMemo, adminListMemo } from '../redux/selectors/smtp'
import { constants, orgAreaMemo } from '../redux/selectors/server'
import { isRootMemo } from '../redux/selectors/workflow'
import * as awssesHandlers from '../redux/actions/async/awsses'
import * as handlers from '../redux/actions/async/smtp'
import { updateAdminFinishEdit } from '../redux/actions/smtp'
import { createDispatchers } from './hocs'
import { createDispatchMap } from './receiptGreeting'

const mapState = (state, props) => ({
  awsses: awssesMemo(state),
  byRegionIdAWSSES: byRegionIdAWSSESMemo(state),
  data: adminListMemo(state),
  editData: adminEditMemo(state),
  oauthConstants: constants(state).oauth,
  orgArea: orgAreaMemo(state),
  view: state.app.admin.ui.view,
  subMenus: state.server.menu.accountMenu ? state.server.menu.accountMenu : emptyArray,
  userIsRoot: isRootMemo(state)
})

const mapDispatch = createDispatchMap(
  'SMTP',
  { ...handlers, updateAdminFinishEdit }
)

const withSmtp = connect(mapState, mapDispatch)

export default withSmtp

const mapAWSSESIdentitiesState = state => ({
  editValue: adminAWSSESEditMemo(state),
  expandedIdentity: identityMemo(state),
  identities: identitiesMemo(state)
})

const mapAWSSESIdentitiesDispatch = createDispatchers(
  'AWSSES',
  { ...awssesHandlers }
)

export const withAWSSESIdentities = connect(
  mapAWSSESIdentitiesState,
  mapAWSSESIdentitiesDispatch
)
