import { compose } from 'recompose'
import { connect } from 'react-redux'
import { EDIT_AGENT, TXT_RAW_DEBUG_INFO } from '../common/v5/chatbotConstants'
import Chatbot from '../reactcomponents/Chatbot'
import { clientMessagesMemo } from '../redux/selectors/chat'
import {
  agentListSelector,
  getFolderListSelector
} from '../redux/selectors/workflow'
import {
  reportAreasSelector
} from '../redux/selectors/statistics'
import {
  adminEditMemo,
  adminChatbotUI,
  chatbotsMemo,
  knowledgebasesMemo,
  reservedEventsMemo,
  selectedClientMessageMemo
} from '../redux/selectors/chatbot'
import { constants } from '../redux/selectors/server'
import {
  adminEdit,
  chatbotIntents,
  deleteChatbot,
  deleteIntent,
  fetchIntent,
  onceOnload,
  questionChatbot,
  save,
  saveChatbot,
  sync
} from '../redux/actions/async/chatbot'
import {
  clearAllQuestions,
  editSelectedTrainPhrase,
  updateAdminEditField,
  updateAdminFinishEdit,
  updateChatbotUIField
} from '../redux/actions/chatbot'
import { togglePopAlert } from '../redux/actions/hmf'
import { withAgentTimezonOffset } from './hocs'

const mapState = (state, props) => ({
  agents: agentListSelector(state, props).all,
  areas: reportAreasSelector(state).active,
  bots: chatbotsMemo(state),
  editData: adminEditMemo(state),
  folders: getFolderListSelector(state),
  libraries: knowledgebasesMemo(state),
  reservedDisplayName: constants(state).chatbotLibQ,
  reservedEvents: reservedEventsMemo(state),
  ui: adminChatbotUI(state),
  adminStatus: state.app.admin.admin.adminStatus
})

const mapDispatch = dispatch => ({
  onChangeChatbotSelection: selected => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('dbg: selected chatbot:', selected)
    }
    dispatch(chatbotIntents(selected))
  },
  onClickDebugInfo: () => dispatch(togglePopAlert(TXT_RAW_DEBUG_INFO)),
  onCloseTestBench: chatbotId => dispatch(clearAllQuestions(chatbotId)),
  onDeleteChatbot: id => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('dbg: delete chatbot:', id)
    }
    dispatch(deleteChatbot(id))
  },
  onDeleteChatbotIntent: (id, iid) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('dbg: delete intent:', id, iid)
    }
    dispatch(deleteIntent(id, iid))
  },
  onEditStart: info => dispatch(adminEdit(info)),
  onEdit: (_, field, operand, value) => dispatch(updateAdminEditField({
    field,
    operand,
    value
  })),
  onFinishEdit: () => dispatch(updateAdminFinishEdit()),
  onLoad: () => dispatch(onceOnload()),
  onPrefetchIntent: (id, iid) => dispatch(fetchIntent(id, iid)),
  onQuestionChatbot: (id, question) => dispatch(questionChatbot(id, question)),
  onSave: (info, changes, data) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('dbg: chatbot save:', info, changes, data)
    }
    if (info.which === EDIT_AGENT) {
      return dispatch(saveChatbot(info, changes, data))
    }
    return dispatch(save(info, changes, data))
  },
  onSync: (info, data) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('dbg: chatbot sync:', info, data)
    }
    return dispatch(sync(info, data))
  },
  onTextInputChange: (e) => {
    dispatch(updateChatbotUIField("searchText", e.target.value))

  },
  onSelectFilter: (v) => {
    dispatch(updateChatbotUIField("filterType", v))
  }
})

const ChatbotCtnr = compose(
  connect(mapState, mapDispatch)
  , withAgentTimezonOffset
)(Chatbot)

export default ChatbotCtnr

const mapClientMessageTrainingPhraseState = state => ({
  clientMessages: clientMessagesMemo(state),
  selectedMessage: selectedClientMessageMemo(state)
})

const mapClientMessageTrainingPhraseDispatch = dispatch => ({
  onChangeSelection: selected => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('dbg: selected client message phrase:', selected)
    }
    dispatch(editSelectedTrainPhrase(selected))
  }
})

export const withClientMessageTrainingPhrase = connect(
  mapClientMessageTrainingPhraseState,
  mapClientMessageTrainingPhraseDispatch
)
